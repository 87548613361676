import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ReactPlayer from 'react-player'

export const CodePostTemplate = ({
  description,
  title,
  youtubeurl,
  helmet,
}) => {

  return (
    <div className="container">
      <section className="main-ctn code-post">
        {helmet || ''}
        <h1 className="title">
          {title}
        </h1>
        <p className="description">{description}</p>

        <div className="player-wrapper">
          <ReactPlayer
            className='react-player'
            url={youtubeurl}
            playing
            controls
            width='100%'
            height='100%'
          />
        </div>

      </section>
    </div>
  )
}

CodePostTemplate.propTypes = {
  youtubeurl: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const CodePost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CodePostTemplate
        youtubeurl={post.frontmatter.youtubeurl}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Code-Post">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

CodePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CodePost

export const pageQuery = graphql`
  query CodePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        youtubeurl
      }
    }
  }
`
